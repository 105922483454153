import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useEffect, Suspense } from 'react';
import { Route, Switch, withRouter, Redirect, useLocation } from 'react-router-dom';

import './App.css';
import './assets/scss/main.scss';
import { actionCreator } from './store/index';
import { lazyWithRetry } from './components/lazyWithRetry';
import ErrorLogReportModal from './services/ErrorLogReportModal';
import { socket, joinUserToRoom } from './services/socket.service';
import { isSuperadminDomain, getUserItem, showToaster } from './services/helper';
import { UPDATE_PREVIEW_RESULT } from './store/Reducers/editProjectReducer/constants';
import { EditProjectActions } from './store/Reducers/editProjectReducer/actions';
import { updateScreenOnProject } from './lib/setting';

const AdminRoute = lazyWithRetry(() => import('./lib/AdminRoute'));
const ClientRoute = lazyWithRetry(() => import('./lib/ClientRoute'));
const ClientRoutes = lazyWithRetry(() => import('./routes/client.route'));
const SuperAdminRoutes = lazyWithRetry(() => import('./routes/superadmin.route'));

const ThankYou = lazyWithRetry(() => import('./modules/ThankYou'));
const AdminLogin = lazyWithRetry(() => import('./modules/login/adminLogin'));
const Subscriptions = lazyWithRetry(() => import('./modules/subscriptions'));

const PageNotFound = lazyWithRetry(() => import('./modules/projects/404page'));
const ClientLogin = lazyWithRetry(() => import('./modules/login/clientLogin'));
const NewRegister = lazyWithRetry(() => import('./modules/login/NewRegister'));
const AdminNoAuthRoute = lazyWithRetry(() => import('./lib/AdminNoAuthRoute'));
const NotFound = lazyWithRetry(() => import('./modules/auth/notfound/NotFound'));

const GenericGlobalComponent = lazyWithRetry(() => import('./components/generic'));
const PlanDetailsPage = lazyWithRetry(() => import('./modules/subscriptions/PlanDetailsPage'));

function App() {
  const dispatch = useDispatch();
  const { UpdateProjectScreenPreview } = bindActionCreators(actionCreator, dispatch);
  const location = useLocation();
  const editProject = useSelector(state => state.editProject);
  const { screen, project, current } = { ...editProject };

  useEffect(() => {
    socket.on('connect', () => {
      joinUserToRoom();
    });
    socket.on('ScreenPreview', data => {
      try {
        console.log('Screen Preview', data);
        const userId = getUserItem('id');
        console.log(data);
        if (userId === data.userId) {
          UpdateProjectScreenPreview(data);
          dispatch(EditProjectActions.setPreview('detail'));
          // if (screen) {
          //   console.log({ ...screen, ...data.mediaurl });
          //   let cloneScreen = { ...screen, ...data.mediaurl };
          //   dispatch(EditProjectActions.setScreen(cloneScreen));
          //   dispatch(EditProjectActions.setPreview('detail'));
          // }

          /* 
          {
    "userId": "6618be42f8faea1d3494d495",
    "projectId": "DdIF8VLqLnj6hCMHoEoTjuEg",
    "moduleId": "M03",
    "slideId": "SL01",
    "screenId": "SC01.01",
    "mediaurl": {
        "PreviewUrl": "https://vidayopro-uat-bucket.s3.amazonaws.com/Projects/DdIF8VLqLnj6hCMHoEoTjuEg/Previews/DdIF8VLqLnj6hCMHoEoTjuEg_M03_SL01_SC01.01final-2024-09-27-11:24:46.mp4?1727436295986",
        "ThumbnailUrl": "https://vidayopro-uat-bucket.s3.amazonaws.com/Projects/DdIF8VLqLnj6hCMHoEoTjuEg/Previews/DdIF8VLqLnj6hCMHoEoTjuEg_M03_SL01_SC01.01thumbnail-2024-09-27-11:24:46.jpg?1727436295986"
    }
}
          */
          dispatch({ type: UPDATE_PREVIEW_RESULT, payload: data });
        }
      } catch (error) {
        console.log({ sockerScreenPreviewError: error });
      }
    });
    socket.on('TrimVideoData', data => {
      try {
        const userId = getUserItem('id');
        if (userId === data.userId) {
          if (data?.data?.error) {
            return showToaster(`Your video ${data.data.fileName} could not be uploaded. ${data.data.data}`, 'error', 5000);
          }

          showToaster(`Your video ${data.data.fileName} has been uploaded and is ready to use.`, 'success', 5000);
        }
      } catch (error) {
        console.log({ sockerScreenPreviewError: error });
      }
    });
    return () => {
      socket.off('connect');
      socket.off('ScreenPreview');
    };
  }, []);

  const isSuperAdmin = isSuperadminDomain;

  function newScript(src) {
    // create a promise for the newScript
    return new Promise(
      function (resolve, reject) {
        // create an html script element
        var script = document.createElement('script');
        // set the source of the script element
        script.src = src;
        // set a listener when the script element finishes loading the script
        script.addEventListener(
          'load',
          function () {
            // resolve if the script element loads
            resolve();
          }.bind(this)
        );
        // set a listener when the script element faces any errors while loading
        script.addEventListener(
          'error',
          function (e) {
            // reject if the script element has an error while loading the script
            reject(e);
          }.bind(this)
        );
        // append the script element to the body
        document.body.appendChild(script);
      }.bind(this)
    );
  }

  // const identifyUser = () => {
  //   const userId = getUserItem('id');
  //   if (userId) {
  //     if (window.Appcues !== undefined && window.Appcues !== null) {
  //       window.Appcues.identify(
  //         userId, // unique, required
  //         {
  //           role: getUserItem('role') || 'SuperAdmin', // Current user’s role or permissions
  //           accountId: getUserItem('clientId'), // Current user's account ID
  //           firstName: getUserItem('firstName'), // current user's first name
  //         }
  //       );
  //     } else {
  //       newScript("//fast.appcues.com/211414.js'")
  //         .then(
  //           function () {
  //             // then recursively call identifyUser to initialize the identity of the user
  //             identifyUser();
  //             // catch any error and print to the console
  //           }.bind(this)
  //         )
  //         .catch(function (error) {
  //           console.log('identifyUser: error on loading script');
  //         });
  //     }
  //   }
  // };

  // useEffect(() => {
  //   identifyUser();
  // }, [location.pathname]);

  return (
    <>
      <ErrorLogReportModal />
      <Suspense fallback={<div></div>}>
        <GenericGlobalComponent />
      </Suspense>

      <Suspense fallback={<div></div>}>
        <Switch>
          <Route path="/:planId/:interval/thank-you" exact component={ThankYou} />

          <Route exact path="/plans">
            {isSuperAdmin ? (
              <Suspense fallback={<div></div>}>
                {' '}
                <AdminNoAuthRoute path="/plans" component={Subscriptions} />
              </Suspense>
            ) : (
              <Suspense fallback={<div></div>}>
                <ClientRoute path="/plans" component={Subscriptions} />
              </Suspense>
            )}
          </Route>
          <Route exact path="/plan/:id">
            {isSuperAdmin ? (
              <Suspense fallback={<div></div>}>
                <PlanDetailsPage />
              </Suspense>
            ) : (
              <ClientRoute path="/plan/:id" component={PlanDetailsPage} />
            )}
          </Route>
          <Route exact path="/:planId/register">
            {isSuperAdmin ? (
              <Suspense fallback={<div></div>}>
                <NewRegister />
              </Suspense>
            ) : (
              <Redirect to="/login" />
            )}
          </Route>

          <Route exact path="/login">
            {isSuperAdmin ? (
              <Suspense fallback={<div></div>}>
                <AdminLogin />
              </Suspense>
            ) : (
              <Suspense fallback={<div></div>}>
                <ClientLogin />
              </Suspense>
            )}
          </Route>

          <Route path="/page-not-found" exact component={PageNotFound} />

          <Route exact path="/">
            {isSuperAdmin ? <Redirect to="/admin" /> : <ClientRoute path="/" component={ClientRoutes} />}
          </Route>
          {/* NOTE: ALWAYS, KEEPS ADMIN_ROUTE ABOVE CLIENT_ROUTE */}
          <AdminRoute path="/admin" component={SuperAdminRoutes} />
          <ClientRoute path="/" component={ClientRoutes} />

          {/* NOTE: DO NOT ADD ANY ROUTE BELOW NOT_FOUND ROUTE  */}
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </>
  );
}

export default withRouter(App);
